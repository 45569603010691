import React,{useState} from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import classes from './Music.module.css'
import Swal from "sweetalert2";
import { codeData } from './MusicCode';
import axios from 'axios';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius:"10px"
};
const btnStlyle={
    backgroundColor: '#eb0909',
    borderRadius: '28px',
    transition: '1s',
    border: '1px solid rgb(237, 17, 17)',
    display: 'inline-block',
    cursor: 'pointer',
    color: '#ffffff',
    marginTop: '10px',
    fontFamily: 'Arial',
    fontSize: '15px',
    padding: '5px 40px',
    textDecoration: 'none',
    textShadow: '0px 1px 0px #2f6627',
    marginBottom:'10px'
}

function BuyModal({id}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [codeNo,setCodeNo] = useState('');

    const submitHandler =(e)=>{
      let success= false;
        e.preventDefault();
        console.log(codeNo);
        axios.post(`${process.env.REACT_APP_DOMAIN}api/invoke-token`, {code: codeNo}).then(res=>{
          if(res.data.status==="success"){
        axios.get(`${process.env.REACT_APP_DOMAIN}api/download-music?music_id=${id}`,{ headers: { authorization: res.data.data.token } }).then(response=>{
if(response.data.status === "success"){
  try {
    const videoUrl = `${process.env.REACT_APP_DOMAIN}${response.data.data.file_path}`;


        const link = document.createElement('a');
        link.href = videoUrl;
        link.setAttribute('download', 'waterfall.mp4');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    
  } catch (error) {
    console.error(error);
  }
}
          // Swal.fire({
          //   icon: "success",
          //   title: "Success !",
          //   text: "Code is Valid",
          //   fontSize: 80,
          // });
          // window.open(response.data.data.url)
        }).catch(err=>{
          Swal.fire({
            icon: "error",
            title: "Error !",
            text: "Invalid Code",
            fontSize: 80,
            
          });
        })
      }
        }).catch(err=>{
          Swal.fire({
            icon: "error",
            title: "Error !",
            text: "Invalid Code",
            fontSize: 80,
            
          });
        })


        // codeData.forEach(element => {
        //   if (element == codeNo) {
        //     success= true;
        //     Swal.fire({
        //       icon: "success",
        //       title: "Success !",
        //       text: "Code is Valid",
        //       fontSize: 80,
        //     });
        //   }    
        // });
        // if(!success){
        //   Swal.fire({
        //     icon: "error",
        //     title: "Error !",
        //     text: "Invalid Code",
        //     fontSize: 80,
            
        //   });
        // }
     


        // codes.for(cc=>{
        //     if(cc==codeNo){
        //         swal('Success')
        //         return
        //     }
        //     else{
        //         swal("Error")
        //     }
        // })

      
    }
  return (
    <div>
      <Button onClick={handleOpen} style={btnStlyle}>Buy</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         
         <input type="text" placeholder="Enter Code" onChange={(e)=>{setCodeNo(e.target.value)}} className={classes.buyInput} ></input> 
         <button className={classes.buyBtn} onClick={submitHandler}>Submit</button>
         <p style={{textAlign: "center"}}>OR</p>
         <button
        
         className= {`${classes["loginBtn"]} ${classes["loginBtn--facebook"]}`}>
          <a style={{
              textDecoration: "none",
              color: "white"
          }} href="https://www.facebook.com/profile.php?id=100087630447354&mibextid=ZbWKwL" target="_blank">
          GO TO FACEBOOK
          </a>

</button>
         <div className={classes.contact}>

       
         </div>
   
        </Box>
      </Modal>
    </div>
  )
}

export default BuyModal